import { useState, useEffect } from "react";

function getWindowDimensions(window) {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useDevice() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions({ innerWidth: 0, innerHeight: 0 })
  );

  const { width } = windowDimensions;

  useEffect(() => {
    function handleResize() {
      if (typeof window !== "undefined") {
        setWindowDimensions(getWindowDimensions(window));
      }
    }

    if (typeof window !== "undefined") {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const device =
    width > 1600
      ? "pc"
      : width > 1300
      ? "pc-small"
      : width > 990
      ? "tablet-large"
      : width > 768
      ? "tablet"
      : width > 400
      ? "phone"
      : "phone-small";

  const isPhone = width < 769;
  const isTablet = width < 991;

  return { device, windowDimensions, isPhone, isTablet };
}
