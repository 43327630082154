import {
  AppleLogo,
  CoinbaseLogo,
  EyeCloseIcon,
  EyeOpenIcon,
  FacebookLogo,
  GoogleLogo,
  LodgerLogo,
  MetamaskLogo,
  TrezorLogo,
  WalletconnectLogo,
} from "assets";
import GradientMain from "components/GradientMain";
import { useUser } from "contexts/user";
import { forgotPassword, loginWithGoogle, loginWithPassword } from "config/firebase";
import { NextPage } from "next";
import Link from "next/link";
import classes from "styles/pages/Login.module.scss";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { isSignedIn, signIn } from "services/auth/auth";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useEthers } from "@usedapp/core";
import useDevice from "hooks/useDevice";

interface Props {}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
});

const forgotSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const login: NextPage<Props> = () => {
  const { user, setUser } = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { account, activateBrowserWallet } = useEthers();
  const { isPhone } = useDevice();

  const { query: { redirect } = {}, push } = useRouter();

  const redirectOnLogin = () => {
    push("/create?step=1")
    // if (redirect === 'create') push("/create?step=1")
    // else push("/")
  }

  useEffect(() => {
    if (isSignedIn(setUser)) redirectOnLogin()
  }, []);

  const googleLogin = () => {
    loginWithGoogle(user, setUser)
      .then(() => {
        redirectOnLogin()
      })
      .catch((err) => {
        if (err.message) toast.error(err.message);
        else toast.error(err);
      });
  };

  const passwordLogin = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    schema
      .validate(data)
      .then(() => {
        loginWithPassword(email, password, user, setUser)
          .then(() => {
            redirectOnLogin()
          })
          .catch((err) => {
            if (err.message) toast.error(err.message);
            else toast.error(err);
          });
      })
      .catch((e) => {
        if (e?.name === "ValidationError") toast.error(e?.errors?.[0]);
      });
  };

  const onForgotPassword = () => {
    const data = {
      email: email
    };
    forgotSchema
      .validate(data)
      .then(() => {
        forgotPassword(email)
          .then((bool) => {
            if (bool) toast.success("Password reset email sent!")
            else toast.error("Something went wrong!")
          })
          .catch((err) => {
            if (err.message) toast.error(err.message);
            else toast.error(err);
          });
      })
      .catch((e) => {
        if (e?.name === "ValidationError") toast.error(e?.errors?.[0]);
      });
  }

  const handleMetamask = () => {
    activateBrowserWallet(() => {
      toast.error("Metamask login failed");
    });
  };

  useEffect(() => {
    if (account) redirectOnLogin()
  }, [account]);

  return (
    <GradientMain className={classes.container}>
      <div className={classes.left}>
        <div className={classes.top}>
          <h2>Welcome Back</h2>
          <p className={classes.tag}>
            Start exploring Enforceable marketplace now.
          </p>
        </div>
        {isPhone && (
          <SignIn
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            forgotPassword={onForgotPassword}
            passwordLogin={passwordLogin}
            googleLogin={googleLogin}
          />
        )}
        <div className={classes.bottom}>
          <p>
            Alternatively you connect an existing Ethereum Wallet to bring in
            your exisiting NFTs
          </p>
          <div className={classes.wallets}>
            <button className={classes.metamask} onClick={handleMetamask}>
              <MetamaskLogo />
              <span>Connect to Metamask</span>
            </button>
            {/* <CoinbaseLogo />
            <TrezorLogo />
            <LodgerLogo />
            <WalletconnectLogo /> */}
          </div>
        </div>
        <p className={classes.sign}>
          <span>Don’t have an account yet?</span>{" "}
          <span>
            <Link href="/register">Sign up</Link>
          </span>
        </p>
      </div>
      <div className={classes.right}>
        {!isPhone && (
          <SignIn
            email={email}
            setEmail={setEmail}
            password={password}
            forgotPassword={onForgotPassword}
            setPassword={setPassword}
            passwordLogin={passwordLogin}
            googleLogin={googleLogin}
          />
        )}
      </div>
    </GradientMain>
  );
};

export default login;

interface SignInProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  forgotPassword: () => void;
  passwordLogin: (e: any) => void;
  googleLogin: () => void;
}

const SignIn: FC<SignInProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  forgotPassword,
  passwordLogin,
  googleLogin,
}) => {
  const [passwordVisible, setPasswordVisible] = useState<Boolean>(false);

  return (
    <>
      <form className={classes.form}>
        <input
          placeholder="Email ID"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={classes.password}>
          <input
            placeholder="Password"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className={classes.eye}
            onClick={() => setPasswordVisible((prev) => !prev)}
          >
            {passwordVisible ? <EyeCloseIcon /> : <EyeOpenIcon />}
          </span>
        </div>
        <p onClick={forgotPassword}>Forgot password?</p>
        <button onClick={passwordLogin}>Login to Your Account</button>
      </form>
      <div className={classes.hr}>
        <span />
        <p>or continue with</p>
        <span />
      </div>
      <div className={classes.oauth}>
        {/* <button className={classes.facebook}>
          <FacebookLogo />
          <p>Continue with Facebook</p>
        </button> */}
        <button onClick={googleLogin} className={classes.google}>
          <GoogleLogo />
          <p>Continue with Google</p>
        </button>
      </div>
    </>
  );
};
